/* THEME COLOURS */
/* TAR: #535353
SAND: #e2d7c1
CITRONA: #dbcc7c
PALM: #c3cfbb
SALT: #e6e4e1
HAZY: #afc2c8
Stone Blue: #7997a1
Card Room Green: #899081 */

.App {
  text-align: center;
}

.navbar-collapse.show {
  min-height: 100vh;
  max-height: 100vh;
}

.App-navbar {
  background-color: #535353;
}

.App-navbar-transparant {
  background-color: transparent;
}

.App-navbar-brand {
  font-style: normal;
}

.navbar-brand {
  color: whitesmoke !important;
}

.navbar-brand:hover {
  color: whitesmoke !important;
}

.nav-link {
  color: whitesmoke !important;
}

.nav-link.active {
  border-bottom: 3px solid whitesmoke;
}

.nav-link:hover {
  color: whitesmoke !important;
}

.App-background {
  background-image: url("./Images/joshua-bartell-6vvIBTvL90A-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.Page-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: whitesmoke;
}

.App-section {
  max-height: calc(100vh - 56px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-section-light {
  display: flex;
  align-items: top;
  justify-content: center;
  background-color: #e6e4e1;
  color: #535353;
}

.App-section-dark {
  min-height: calc(100vh - 56px);
  display: flex;
  align-items: top;
  justify-content: center;
  background-color: #b5b5b5;
  color: #535353;
}

.App-section-contact {
  min-height: calc(100vh - 56px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
}

.App-section-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c3cfbb;
}

.App-section-col {
  padding: 32px;
}

.App-section-col-contact {
  max-width: 40% !important;
}

.App-section-col-text {
  text-align: left;
  padding: 16px;
}

.Privacy-section-col-text {
  text-align: left;
}

.Footer-section-col {
  padding: 32px;
}

.Footer-section-col-text {
  text-align: center;
  padding: 16px;
}

.App-section-footer {
  min-height: calc(50vh - 56px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-footer-socials {
  display: block !important;
  text-align: center;
  padding: 16px;
}

.App-about-services-section-image {
  min-height: 80vh;
  background-image: url("./Images/mohammad-rahmani.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: rgba(0, 0, 0, 0.5);
}

.App-about-values-section-image {
  min-height: 80vh;
  background-image: url("./Images/jason-goodman.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.App-about-section-text {
  position: "absolute";
  justify-content: "center";
  align-items: "center";
  color: #535353;
}

.carousel-caption {
  top: 50%;
  transform: translateY(-50%);
  bottom: initial !important;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

@media screen and (max-width: 1210px) {
  div.App-section-col {
    min-width: 400px;
  }
  div.App-section-col-contact {
    max-width: 50% !important;
  }
  /* .nav-link {
    margin-top: 0px;
  } */
}

@media screen and (max-width: 991px) {
  div.App-section-col {
    min-width: 400px;
  }
  div.App-section-col-contact {
    max-width: 60% !important;
  }
}

@media screen and (max-width: 768px) {
  div.App-section-col {
    min-width: 400px;
  }
  div.App-section-col-contact {
    max-width: 70% !important;
  }
  .App-section-dark {
    flex-direction: column-reverse;
  }
  div.App-recaptcha-style {
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}

@media screen and (max-width: 435px) {
  div.App-section-col {
    min-width: 280px;
  }
  div.App-section-col-contact {
    max-width: 80% !important;
  }
  div.App-recaptcha-style {
    transform: scale(0.7);
    transform-origin: 0 0;
  }
}

@media screen and (max-width: 280px) {
  div.App-section-col {
    min-width: 280px;
  }
  div.App-section-col-contact {
    max-width: 85% !important;
  }
  div.App-recaptcha-style {
    transform: scale(0.7);
    transform-origin: 0 0;
  }
}

.inline-errormsg {
  color: whitesmoke;
  font-size: 12px;
  text-align: left;
}

.icon-link {
  padding: 15px;
}

a:link {
  color: #272725;
}

a:hover {
  color: #272725 !important;
}

.btn-contact {
  color: #535353 !important;
  background-color: #c3cfbb !important;
  border-color: #c3cfbb !important;
}

.btn-menu {
  color: #535353 !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.offcanvas-header .btn-close {
  color: whitesmoke;
}

.btn:hover {
  color: #f1f1ef !important;
}

.__rcs-handle-button {
  backdrop-filter: blur(0px) !important;
}
